:root {
  --font-family-sans-serif: "EmojiCSS", "AvenirCSS", Arial, sans-serif;
  --header-height: 80px;
  --app-width: 1080px;
  --sidebar-width: 240px;

  --font-size-small: 12px;
  --font-size-normal: 14px;
  --font-size-medium: 16px;
  --font-size-large: 18px;
  --font-size-larger: 24px;
  --font-size-huge: 34px;

  --line-height-base: 1.4;
  --line-height-normal: 19px;

  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;
  --font-weight-black: 900;

  --color-text: #4a4a4a;
  --color-background: #ffffff;
  --color-text-light: #666666;
  --color-text-disabled: #e6e6e6;
  --color-border: #e6e6e6;
  --color-border-light: #fafafa;
  --color-brand: #2770ee;
  --color-brand-alpha-20: rgba(39, 112, 238, 0.2);
  --color-brand-rgb: 39, 112, 238;
  --color-error: #f24622;
  --color-error-rgb: 242, 70, 34;
  --color-warning: #fec600;
  --color-warning-rgb: 254, 198, 0;

  --color-contrast-alpha-08: rgba(0, 0, 0, 0.08);

  --focus-box-shadow: 0 0 0 3px var(--color-brand-alpha-20),
    0 1px 1px 0 var(--color-contrast-alpha-08);
}
