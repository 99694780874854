.menu {
  background: var(--color-background);
  box-shadow: 0 3px 10px 0 rgba(49, 49, 49, 0.2), 0 0 0 1px #fafafa;
  border-radius: 2px;
  padding: 5px 0;
  list-style: none;
  margin: 0;
}

.menu ul {
  list-style: none;
  padding: 0;
}

/* .menu {
  outline: none;
}

.menu:focus-visible {
  outline: unset;
}

.menu-item {
  outline: none;
}

.menu-item:focus-visible {
  outline: unset;
} */

.menu-item {
  display: grid;
  grid-template-areas: "icon text";
  grid-template-columns: 20px 1fr;
  grid-column-gap: 8px;
  cursor: pointer;

  padding: 8px 24px 8px 16px;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-light);

  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);
}

.menu-item .icon {
  grid-area: icon;
}

.menu-item:not([aria-disabled="true"]) .icon {
  color: var(--color-brand);
}

.menu-item > span {
  grid-area: text;
  white-space: nowrap;
}

.menu-item[aria-disabled="true"] {
  color: var(--color-text-disabled);
  cursor: default;
}

.menu-item.divider {
  margin: 5px 10px;
  padding: 0 24px 0 8px;
  height: 1px;
  background: var(--color-border-light);
  cursor: default;
}

/* .dropdown-menu .discreet.menu-item {
  opacity: 0.6;
}
.dropdown-menu .discreet.menu-item:not([disabled]):hover,
.dropdown-menu .discreet.menu-item:not([disabled]):focus {
  opacity: 1;
} */

/* .menu .menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  padding: 8px 24px 8px 8px;
  font-weight: var(--font-weight-bold);
  color: var(--color-text-light);

  font-size: var(--font-size-normal);
  line-height: var(--line-height-normal);
} */

.menu-item:focus {
  color: var(--color-brand);
  background: var(--color-border-light);
  outline: none;
}

.menu-item:focus-visible {
  outline: 0;
  box-shadow: inset var(--focus-box-shadow);
}
