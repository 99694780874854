.modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 0 20px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-backdrop {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-dialog {
  position: relative;
  z-index: 2;
  display: flex;
  outline: none;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  min-width: 460px;
  max-width: 460px;
  margin: 80px auto;
}

.modal-dialog-header {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px 20px 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-huge);
  color: var(--color-text-light);
}

.modal-dialog-flash {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 0;
  min-height: 34px;
  line-height: 34px;
  font-size: var(--font-size-small);
}

.modal-dialog-flash-close {
  position: absolute;
  flex: 0 0 auto;
  border: none;
  background: none;
  color: inherit;
  width: 34px;
  height: 34px;
  cursor: pointer;
}

.modal-dialog-flash-inner {
  flex: 1 1 auto;
  text-align: center;
}

.modal-dialog-flash--type-info {
  background-color: var(--color-brand);
  color: var(--color-background);
}

.modal-dialog-flash--type-error {
  background-color: var(--color-error);
  color: var(--color-background);
}

.modal-dialog-body {
  flex: 1 1 auto;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-medium);
  color: var(--color-text);
}

.modal-dialog-body-inner {
  padding: 20px;
}

.modal-dialog-footer {
  flex: 0 0 auto;
  padding: 0 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.modal-dialog-footer .button + .button {
  margin-left: 10px;
}
