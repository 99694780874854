.banner {
  grid-area: banner;
  padding: 20px;
  font-size: var(--font-size-medium);
}

.banner p + p {
  margin-top: 8px;
}

.banner--error {
  color: var(--color-error);
  background-color: rgba(var(--color-error-rgb), 0.1);
}

.banner--info {
  color: var(--color-brand);
  background-color: rgba(var(--color-brand-rgb), 0.1);
}

.banner--warning {
  color: var(--color-warning);
  background-color: rgba(var(--color-warning-rgb), 0.1);
}
