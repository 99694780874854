.form-list-item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  border-radius: 2px;
  padding: 0 20px;
  font-weight: var(--font-weight-bold);
  color: #898989;
}

.form-list-item.active {
  color: white;
  background-color: var(--color-brand);
}

.form-list-item.active .form-list-item_unread-count {
  opacity: 1;
}

.form-list-item_unread-count {
  opacity: 0.4;
  margin-left: 10px;
}
