.form-list {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.form-list_header {
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text-light);
  padding: 10px 20px;
}

.form-list_header_title {
  flex: 1 1 auto;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-medium);
}

.form-list_header_counter {
  flex: 0 0 auto;
}

.form-list_new-form {
  justify-content: flex-start;
  padding: 0 20px;
  color: var(--color-brand);
}
