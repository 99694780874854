.button {
  user-select: none;
  /* outline: none; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-normal);
  border: none;
  cursor: pointer;
}

.button.disabled {
  opacity: 0.4;
  cursor: default;
}

.button:focus-visible,
.button--focused {
  outline: 0;
  box-shadow: var(--focus-box-shadow);
  border-color: var(--color-brand);
}

.button:not(.disabled):hover {
  opacity: 0.9;
}

.button:not(.disabled):active {
  opacity: 0.8;
}

.button--default,
.button--primary,
.button--primary-alt,
.button--error,
.button--error-alt {
  border-radius: 2px;
  padding: 0 24px;
  height: 40px;
}

.button--icon {
  padding: 0;
  width: 40px;
  height: 40px;
  text-align: center;
}

.button--default {
  border: 1px solid var(--color-border);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: var(--color-text-light);
  background: #fff;
}

.button--primary {
  color: #fff;
  background: var(--color-brand);
}

.button--primary-alt {
  border: 1px solid var(--color-border);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: var(--color-brand);
  background: #fff;
}

.button--error {
  color: #fff;
  background: var(--color-error);
}

.button--error-alt {
  border: 1px solid var(--color-border);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: var(--color-error);
  background: #fff;
}

.button--link {
  color: var(--color-text);
  background: none;
  border: none;
  box-shadow: none;
  /* padding: 0;
  height: auto; */
}

.button--link.button--primary {
  color: var(--color-brand);
}
.button--link.button--error {
  color: var(--color-error);
}
