.icon {
  display: inline-block;
  vertical-align: middle;
  vertical-align: sub;
}

.icon--style-default {
  fill: currentColor;
}

.icon--style-error {
  fill: var(--color-error);
}

.icon--size-base {
  width: 24px;
  height: 24px;
}

.icon--size-small {
  width: 20px;
  height: 20px;
}
