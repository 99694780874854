@font-face {
  font-family: EmojiCSS;
  src: local("Apple Color Emoji"), local("Android Emoji"), local("Segoe UI"),
    local(EmojiSymbols), local(Symbola);
  unicode-range: U+1F300-1F5FF, U+1F600-1F64F, U+1F680-1F6FF, U+2600-26FF;
}
@font-face {
  font-family: "AvenirCSS";
  src: url(./fonts/Avenir-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "AvenirCSS";
  src: url(./fonts/Avenir-Roman.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "AvenirCSS";
  src: url(./fonts/Avenir-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "AvenirCSS";
  src: url(./fonts/Avenir-Heavy.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "AvenirCSS";
  src: url(./fonts/Avenir-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  min-width: 1024px;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-size-normal);
  line-height: var(--line-height-base);
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
  margin: 0;
}

p {
  margin: 0;
}
p a {
  color: var(--color-brand);
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
a:not(.disabled):hover {
  opacity: 0.9;
}
a:not(.disabled):active {
  opacity: 0.8;
}

pre,
code,
input,
textarea,
select {
  font-family: var(--font-family-sans-serif);
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.logo {
  display: flex;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  fill: currentColor;
}
.logo svg {
  display: block;
}
.logo .logo-title {
  letter-spacing: -1px;
  margin-left: 0.3571428571em;
}

hr {
  position: relative;
  border: none;
  height: 1px;
  background: #e6e6e6;
  margin: 20px 0;
}

.pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.flex-spacer {
  flex: 1 1;
}
