.app {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.app-wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  max-width: var(--app-width);
  margin: 0 auto;
  padding: 0 20px;
}

.app-header {
  flex: 0 0 var(--header-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
  height: var(--header-height);
}
.app-header .logo {
  fill: var(--color-brand);
}

.app-wrapper--header {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.app-content {
  grid-area: main;
  width: 100%;
  display: grid;
  overflow: auto;
  grid-template-areas:
    "sidebar banner"
    "sidebar main";
  grid-template-columns: var(--sidebar-width) 1fr;
  grid-auto-rows: auto 1fr;
  max-width: var(--app-width);
  margin: 0 auto;
  padding: 0 20px;
}

.app-header_group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-header_group .button {
  margin-left: 40px;
}

.sidebar {
  grid-area: sidebar;
  flex: 1 1 var(--sidebar-width);
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--color-border);
  padding: 20px 0;
}
.sidebar hr {
  margin: 10px 20px;
}
