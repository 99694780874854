.app-loading-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-loading-screen_logo {
  fill: var(--color-brand);
}

.app-loading-screen_text {
  font-size: var(--font-size-large);
  margin: 20px 0 0;
}
